import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';

const UploadProducts = () => {
    const [categories, setCategories] = useState(null);
    const { isLoading: isLoadingCategories, refetch: getCategories } = useQuery(
        "categories",
        async () => {
          return await apiClient.get("/api/categories");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setCategories(res.data.categories);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      
    const dispatch = useDispatch();
    
    const initialLogin = {
        name: '',
        quantity: '',
        unit_price: '',
        unit_profit: '',
        cost_per_price: '',
        meal_type: '',
        category_id: '',
        nutritional_value: '',
        ingredients: '',
        short_description: '',
        description: '',

    }
    
    
    const [error, setErros] = useState('');
    const [productForm, setproductForm] = useState(initialLogin);
    const appMode = useSelector((state) => state.mode.mode);

    const onChange = (e) =>
    setproductForm({ ...productForm, [e.target.name]: e.target.value });

    const initialImage = {
        image: ''
    }
    
    const [productImage, setImage] = useState(initialImage);
    const  onFileChange = (event) => { 
        setImage({...productImage, image: event.target.files[0]});
      }; 

      

    const { isLoading: isSendingRequest, mutate: postProduct } = useMutation(

        async () => {
            const formData = new FormData();
            formData.append('name', productForm.name);
            formData.append('unit_price', productForm.unit_price);
            formData.append('unit_profit', productForm.unit_profit);
            formData.append('cost_per_price', productForm.cost_per_price);
            formData.append('meal_type', productForm.meal_type);
            formData.append('category_id', productForm.category_id);
            formData.append('nutritional_value', productForm.nutritional_value);
            formData.append('ingredients', productForm.ingredients);
            formData.append('short_description', productForm.short_description);
            formData.append('description', productForm.description);
            formData.append('quantity', productForm.quantity);
            formData.append('product_image', productImage.image);

          return await apiClient.post(`/api/store-product`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
          },
          onError: (err) => {   
            let myerror = err.response?.data || err;         
            setErros(myerror.errors)  
            const event2 = new Event('processed');
            window.dispatchEvent(event2);     
          },
        }
      );
      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postProduct();
      } 

      useEffect(() => {
        getCategories();
      }, []);

  return (
    <div style={{marginTop: '70px'}}>
        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        UPLOAD PRODUCTS
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Fill the form below to upload products
                    </Typography>

                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="Product Name">Product Name</InputLabel>
                            {(error !== '' && error.name) ?
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="name"
                            name="name"
                            type={'text'}
                            label="Product Name"
                            helperText={error.name}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="name"
                            name="name"
                            type={'text'}
                            label="Product Name"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.name}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="unit_price">Unit Price</InputLabel>
                            {(error !== '' && error.unit_price) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="unit_price"
                            type={'number'}
                            name="unit_price"
                            label="Unit Price"
                            helperText={error.unit_price}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="unit_price"
                            type={'number'}
                            name="unit_price"
                            label="Unit Price"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.unit_price}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="unit_profit">Unit Profit</InputLabel>
                            {(error !== '' && error.unit_profit) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="unit_profit"
                            type={'number'}
                            name="unit_profit"
                            label="Unit Profit"
                            helperText={error.unit_profit}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="unit_profit"
                            type={'number'}
                            name="unit_profit"
                            label="Unit Profit"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.unit_profit}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="cost_per_price">Cost of Production Per Unit</InputLabel>
                            {(error !== '' && error.cost_per_price) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="cost_per_price"
                            type={'number'}
                            name="cost_per_price"
                            label="Cost of Production Per Unit"
                            helperText={error.cost_per_price}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="cost_per_price"
                            type={'number'}
                            name="cost_per_price"
                            label="Cost of Production Per Unit"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.cost_per_price}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="quantity">Quantity</InputLabel>
                            {(error !== '' && error.quantity) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="quantity"
                            type={'number'}
                            name="quantity"
                            label="Quantity"
                            helperText={error.quantity}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="quantity"
                            type={'number'}
                            name="quantity"
                            label="Quantity"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.quantity}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="meal_type">Select Meal Type</InputLabel>
                            {(error !== '' && error.meal_type) ?
                            <Select
                            error
                            onChange={onChange}
                            labelId="meal_type"
                            id="meal_type"
                            name="meal_type"
                            label={`Select Meal Type`}
                            helperText={error.meal_type}
                            >
                            <MenuItem selected value=''>
                                select meal type
                            </MenuItem>              
                            <MenuItem value="Breakfast">Breakfast </MenuItem>
                            <MenuItem value="Lunch">Lunch </MenuItem>
                            <MenuItem value="Dinner">Dinner </MenuItem>
                        </Select>
                            :
                            <Select
                                onChange={onChange}
                                labelId="meal_type"
                                name="meal_type"
                                id="meal_type"
                                label={`Select Meal Type`}
                                >
                                <MenuItem selected value=''>
                                    select meal type
                                </MenuItem>              
                                <MenuItem value="Breakfast">Breakfast </MenuItem>
                                <MenuItem value="Lunch">Lunch </MenuItem>
                                <MenuItem value="Dinner">Dinner </MenuItem>
                            </Select>
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.meal_type}
                            </Typography>
                        </FormControl>


                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="category_id">Product Category</InputLabel>
                            {(error !== '' && error.category_id) ?
                            <Select
                                error
                                onChange={onChange}
                                labelId="category_id"
                                id="category_id"
                                name="category_id"
                                label='Product Category'
                                helperText={error.category_id}
                                >
                                <MenuItem selected value=''>
                                    select category
                                </MenuItem> 
                                {categories !== null &&
                                categories.map(category =>(
                                    <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                                ))
                                }             
                            </Select>
                            :
                            <Select
                                onChange={onChange}
                                labelId="category_id"
                                id="category_id"
                                name="category_id"
                                label='Product Category'
                                >
                                <MenuItem selected value=''>
                                    select category
                                </MenuItem> 
                                {categories !== null &&
                                categories.map(category =>(
                                    <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                                ))
                                }             
                            </Select>
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.category_id}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="nutritional_value">Nutritional Value</InputLabel>
                            {(error !== '' && error.nutritional_value) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={2} multiline
                            id="nutritional_value"
                            name="nutritional_value"
                            type={'text'}
                            label="Nutritional Value"
                            helperText={error.nutritional_value}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={2} multiline
                            id="nutritional_value"
                            name="nutritional_value"
                            type={'text'}
                            label="Nutritional Value"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.nutritional_value}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="ingredients">List Of Ingredients</InputLabel>
                            {(error !== '' && error.ingredients) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={2} multiline
                            id="ingredients"
                            name="ingredients"
                            type={'text'}
                            label="List Of Ingredients"
                            helperText={error.ingredients}
                            />
                            :
                            <OutlinedInput
                            minRows={2} multiline
                            onChange={onChange}
                            id="ingredients"
                            name="ingredients"
                            type={'text'}
                            label="List Of Ingredients"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.ingredients}
                            </Typography>
                        </FormControl>

                        <FormControl  fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="short_description">Short Description</InputLabel>
                            {(error !== '' && error.short_description) ?
                            <OutlinedInput
                            error
                            helperText={error.short_description}
                            onChange={onChange}
                            minRows={3} multiline
                            id="short_description"
                            name="short_description"
                            type={'text'}
                            label="Short Description"
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={3} multiline
                            id="short_description"
                            name="short_description"
                            type={'text'}
                            label="Short Description"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.short_description}
                            </Typography>
                        </FormControl>

                        <FormControl  fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="address">Detailed Product Description</InputLabel>
                            {(error !== '' && error.description) ?
                            <OutlinedInput
                            error
                            helperText={error.description}
                            onChange={onChange}
                            minRows={4} multiline
                            id="description"
                            name="description"
                            type={'text'}
                            label="Detailed Product Description"
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={4} multiline
                            id="description"
                            name="description"
                            type={'text'}
                            label="Detailed Product Description"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.description}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={false} sx={{ m: 1, minWidth: 50 }}>
                        <Typography  sx={{  textAlign: 'center', fontSize: 16, fontWeight: 'bold'  }} variant="subtitle1" gutterBottom>
                            Upload Product Image
                        </Typography>
                        <Box>
                        {productImage.image !== '' &&
                            <img style={{width: "180px", height: "auto"}} src={URL.createObjectURL(productImage.image)} alt="product"/>
                        }
                        </Box>
                            {(error !== '' && error.product_image) ?

                            <OutlinedInput
                            onChange={onFileChange}
                            error
                            accept="image/*"
                            id="product_image"
                            type='file'
                            name="product_image"
                            helperText={error.product_image}
                            />
                            :
                            <OutlinedInput
                            accept="image/*"
                            onChange={onFileChange}
                            id="product_image"
                            type='file'
                            name="product_image"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.product_image}
                            </Typography>
                        </FormControl>
                    </CardContent>   

                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Uploading...' : 'Upload Product'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    </div>
  )
}

export default UploadProducts
