import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mode: localStorage.getItem('mode') || 'light' 
}

export const modeSlice = createSlice({
    name: 'mode',
    initialState,
    reducers: {
        switchMode: (state, action) =>{
            state.mode = action.payload;  
            localStorage.setItem('mode', state.mode);
          
        }       
    }
})

export const {switchMode} = modeSlice.actions;
export default modeSlice.reducer;